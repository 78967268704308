import React from 'react';
import { graphql } from 'gatsby';
import HeroSpace from '../components/HeroSpace/HeroSpace';
import ContentWrapper from '../components/ContentWrapper/ContentWrapper';

import legalDocIcon from '../../assets/pages/legal/legal-doc.svg';

import styles from './legal.module.scss';

import { bg, flex, combine } from '../style/utils';

const NotFoundPage = ({ data }) => (
  <div>
    <HeroSpace
      titleClass={styles.title}
      title="Terms of use"
      image={{
        src: legalDocIcon,
        alt: 'Icon showing legal document',
        className: styles.heroGraphic,
        wrapperClass: combine(flex.col, styles.heroWrapper),
      }}
    />
    <ContentWrapper backgroundClasses={bg.lightBlue} variant="narrow">
      <div dangerouslySetInnerHTML={{ __html: data.terms.html }} />
    </ContentWrapper>
  </div>
);

export default NotFoundPage;

export const pageQuery = graphql`
  query getTerms {
    terms: markdownRemark(fileAbsolutePath: { regex: "/terms-of-use/" }) {
      html
    }
  }
`;
